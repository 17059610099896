import React, { Suspense, lazy } from 'react';
import './App.css';
import '../src/css/fonts.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// components
import Navbar from "./components/common/navbar/Navbar";
import Footer from "./components/common/footer/Footer";
import Scroll from './helpers/scroll';
import Steam from './pages/services/steam/Steam';
import Home from './pages/home/Home';

// Lazy loaded components
const Content = lazy(() => import('./pages/content/Content'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Cases = lazy(() => import('./pages/cases/Cases'));
const Services = lazy(() => import('./pages/services/Services'));
const Privacy = lazy(() => import('./pages/privacy/Privacy'));
const IGaming = lazy(() => import('./pages/services/igaming/iGaming'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Scroll />
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/content" element={<Content />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/steam" element={<Steam />} />
            <Route path="/services/igaming" element={<IGaming />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;