import React, { useState } from 'react';
import { Loader } from '../loader/Loader';
import { useSendJKMEmail } from '../../hooks/useSendEmail';
import './FreeConsultation.css';

const discordIcon = '/img/icons/discord-pink.png';
const steamIcon = '/img/icons/steam-pink.png';

const FreeConsultation = ({ showConsultation, setShowConsultation }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        termsAccepted: false,
        preferredContact: 'discord'
    });

    const [validationErrors, setValidationErrors] = useState({
        name: '',
        email: '',
        message: '',
        termsAccepted: '',
        preferredContact: ''
    });

    const { sendEmail, loading, error, success, setError, setSuccess } = useSendJKMEmail();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateForm = () => {
        success && setSuccess('');
        error && setError('');

        const errors = {
            name: '',
            email: '',
            message: '',
            termsAccepted: '',
            preferredContact: ''
        };

        if (!formData.name)
            errors.name = 'Name is required.';

        if (!formData.email)
            errors.email = formData.preferredContact === 'email' ? 'We need an email to be able to contact you.' : `Please provide your ${formData.preferredContact} username.`;

        if (!formData.message)
            errors.message = 'Please tell us a little bit about your project.';

        if (!formData.termsAccepted)
            errors.termsAccepted = 'You must accept the terms and conditions.';

        setValidationErrors(errors);

        return Object.values(errors).every(err => !err);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const emailData = {
            name: formData.name,
            email: formData.email,
            message: formData.message,
            type: 'consultation',
            preferredContact: formData.preferredContact
        };

        const emailSent = await sendEmail(emailData);

        if (emailSent) {
            window.gtagSendEvent();
            setFormData({ name: '', email: '', message: '', termsAccepted: false, preferredContact: 'discord' });
        }
    };

    const formRef = React.useRef();
    const [isClosing, setIsClosing] = React.useState(false);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setIsClosing(true);
                setTimeout(() => setShowConsultation(false), 250);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowConsultation]);

    const renderSocialMediaInstructions = () => {
        if (formData.preferredContact === 'discord') {
            return (
                <div className="social__media__instructions">
                    <a href="https://discordapp.com/users/237596991050219521" target="_BLANK" rel="noreferrer"
                        onClick={() => window.gtagSendEvent()}>
                        <img src={discordIcon} alt="Discord icon" height="50px" width="50px" />
                    </a>
                    <p>
                        Add us on Discord: <a href="https://discordapp.com/users/237596991050219521" target="_BLANK" rel="noreferrer" onClick={() => window.gtagSendEvent()}>kevalane</a> and leave a message so we know to add you.
                    </p>
                </div>
            );
        } else if (formData.preferredContact === 'steam') {
            return (
                <div className="social__media__instructions">
                    <a href="https://steamcommunity.com/profiles/76561198166295458" target="_BLANK" rel="noreferrer"
                        onClick={() => window.gtagSendEvent()}>
                        <img src={steamIcon} alt="Steam icon" height="50px" width="50px" />
                    </a>
                    <p>
                        Add us on Steam: <a href="https://steamcommunity.com/profiles/76561198166295458" target="_BLANK" rel="noreferrer" onClick={() => window.gtagSendEvent()}>kevalane</a> and leave a comment so we know to add you.
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <aside className={`consultation-form ${isClosing ? 'slide-out' : ''}`} ref={formRef}>
            <div className="close" aria-label="Close" onClick={() => {
                setIsClosing(true);
                setTimeout(() => setShowConsultation(false), 250);
            }}>
                <span aria-hidden="true">&times;</span>
            </div>
            <h3>Let's start something great.</h3>
            <p className="intro-text text-light pb-3">
                {formData.preferredContact === 'email' ? <span>Fill in the form below</span> : <span>Add us</span>} and tell us more about the project you are looking to get done.
                We'll be in touch shortly!
            </p>
            <form onSubmit={handleFormSubmit}>
                {formData.preferredContact === 'email' && <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text"
                        className={`form-control ${validationErrors.name ? 'is-invalid' : ''}`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name" />
                    {validationErrors.name && <div className="invalid-feedback">{validationErrors.name}</div>}
                </div>}

                <div className="mb-3">
                    <label htmlFor="preferredContact" className="form-label">Preferred Contact Method</label>
                    <select
                        className={`form-control ${validationErrors.preferredContact ? 'is-invalid' : ''}`}
                        id="preferredContact"
                        name="preferredContact"
                        value={formData.preferredContact}
                        onChange={handleInputChange}
                    >
                        <option value="email">Email</option>
                        <option value="discord">Discord</option>
                        <option value="steam">Steam</option>
                    </select>
                    {validationErrors.preferredContact && <div className="invalid-feedback">{validationErrors.preferredContact}</div>}
                </div>

                {renderSocialMediaInstructions()}

                {formData.preferredContact === 'email' && (<>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            {formData.preferredContact === 'email' ? 'Email' : formData.preferredContact === 'discord' ? 'Discord Username' : 'Steam Username'}
                        </label>
                        <input type="text"
                            className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder={formData.preferredContact === 'email' ? 'name@example.com' : formData.preferredContact === 'discord' ? 'Discord Username' : 'Steam Username'} />
                        {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea className={`form-control ${validationErrors.message ? 'is-invalid' : ''}`}
                            id="message"
                            name="message"
                            value={formData.message}
                            rows={4}
                            onChange={handleInputChange}
                            placeholder="Message"></textarea>
                        {validationErrors.message && <div className="invalid-feedback">{validationErrors.message}</div>}
                    </div>

                    <div className="mb-3 form-check">
                        <input type="checkbox"
                            className={`form-check-input ${validationErrors.termsAccepted ? 'is-invalid' : ''}`}
                            id="termsAccepted"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleInputChange} />
                        <label htmlFor="termsAccepted" className="form-check-label">I have read and accept the terms of JKM Solutions' <a href="/privacy" target="_BLANK" rel="noreferrer">privacy policy.</a></label>
                    </div>

                    <button type="submit" className="btn">Send</button>

                </>)}

                {loading && <Loader />}
                {error && <p className="error-message mt-3">{error}</p>}
                {success && <p className="success-message mt-3">{success}</p>}
            </form>
        </aside>
    );
}

export default FreeConsultation;
